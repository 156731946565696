$(document).ready(function() {
    $.ajaxSetup({ cache: false });

    // SLIDER interactie
    $('.top-carrousel ul').bxSlider({
        mode: 'fade',
        pager: false,
        controls: true,
        auto: true,
        //nextSelector: $('.pageheader .next_btn'),
        //prevSelector: $('.pageheader .prev_btn'),
        nextText: '',
        prevText: '',
        touchEnabled: true
    });


    $('.toggle-play').click(function(e) {
        $(this).toggleClass('active');

        if($(this).hasClass('active')){
            console.log('play');
            homeslider.startAuto();
        }else{
            console.log('pause');
            homeslider.stopAuto();
        }
    });
    

    //$(".fancybox").fancybox();

    // SLIDER interactie
    /*var prodslides = $('.product-fotos').bxSlider({
        pagerCustom: '#bx-pager-product-fotos',
        mode: 'fade',
        controls: false,
        auto: false,
        touchEnabled: false
    });
    $('.product-stalen').bxSlider({
        pager: false,
        controls: true,
        auto: true,
        touchEnabled: false,
        onSlideNext: function($slideElement, oldIndex, newIndex){
            prodslides.goToNextSlide();
        },
        onSlidePrev: function($slideElement, oldIndex, newIndex){
            prodslides.goToPrevSlide();
        }
    });*/


    $('.block').fitVids();


    //equalHeights('.quote-item', '.quotes-224 .col1');
    //equalHeights('.kader','.content-25');
    //equalHeights('.kader','.content-26');
    //imagesToBackground();

    /*
    Create dynamic BXslider
    Needed for blocks: 100
    */
    createBXslider('.bxslider');

    $('.referenties-201 ul').bxSlider({
        pager: false,
        controls: false,
        auto: true,
		minSlides: 6,
		maxSlides: 6,
		pause: 8000,
		slideWidth: 170,
		slideMargin: 30,
        touchEnabled: false
    });

    /*
    Extra content toggle
    Needed for blocks: 15
    */
    $('.toggle_extra_content a').click(function(e) {
        //$(this).fadeToggle();
        e.preventDefault();
        $(this).toggleClass('up down');
        $(this).parent().parent().parent().find('.extra_content').slideToggle();
    });

    if ($('.gmap-leden').length > 0) init_leden(); 

    /*
    Gmap3 functionality
    Needed for blocks: 130, 131
    */
    $('.gmap').each(function( index ) {
        gmap_address = $(this).data('address');
        //console.log(gmap_address);
        $(this).gmap3({
            marker: {
                address: gmap_address,
                events: {
                    click: function(marker, event, data) {
                        window.open("https://maps.google.com/?daddr="+gmap_address);
                    }
                }
            },
            map:{
                options:{
                    zoom:16,
                    scrollwheel: false,
                }
            }
        });
    });


    /*var filterOnLoad = window.location.hash ? '.'+(window.location.hash).replace('#','') : 'all';
    $('#projecten-mix').mixItUp({
        controls: {
            enable: true
        },
        load: {
            filter: filterOnLoad,
        }
    });*/

    $('#bs-menu a[href*="#"]').click(function(e) {
        e.preventDefault();
        var parts = $(this).prop('href').split('#');
        var hash = parts[1];
        if ($('.project-filters a.filter').filter('[data-filter=".'+ hash +'"]').length > 0)
        {
            $('.project-filters a.filter').filter('[data-filter=".'+ hash +'"]').trigger('click');
        } else
        {
            window.location.href = $(this).prop('href');
        }
    });

    $('.project-fotos').bxSlider({
        pager: false,
        controls: true,
        auto: true,
        touchEnabled: false
    });


    $('a.filter').click(function() {
        window.location.hash = $(this).data('filter').replace('.', '');
    });


    $("#project-filter").keyup(function(){
        // Delay function invoked to make sure user stopped typing
        delay(function(){
            var $matching = $();
            var inputText = $('#project-filter').val().toLowerCase();

            // Check to see if input field is empty
            if ((inputText.length) > 0) {
                $( '.mix').each(function() {
                    // add item to be filtered out if input text matches items inside the title

                    if($(this).text().toLowerCase().match(inputText)) {
                        $matching = $matching.add(this);
                    }
                    else {
                        // removes any previously matched item
                        $matching = $matching.not(this);
                    }
                });
                $("#projecten-mix").mixItUp('filter', $matching);
            }

            else {
                // resets the filter to show all item if input is empty
                $("#projecten-mix").mixItUp('filter', 'all');
            }
        }, 200 );
    });

    $("#contact-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/contact.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

	$("#leden-aanmeld-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/lidworden.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });
	
	$("#leden-update-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/lidupdate.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });
	
	$("#seminar-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-warning', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/seminar.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
					$(form)[0].reset();
                    
                } else
                {
					if (data.duplicate)
                    	$('.alert-warning', form).removeClass('hidden');
					else
                    	$('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });
	$("#seminar-no-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-warning', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/seminar-no.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    
                } else
                {
					if (data.duplicate)
                    	$('.alert-warning', form).removeClass('hidden');
					else
                    	$('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

	
    $("#login-form").validate({
        submitHandler: function(form) {
                $('.alert-danger', form).addClass('hidden');
                $('.alert-success', form).addClass('hidden');
                $.post(window.base_url +"engine/login.php", $(form).serializeArray(), function(data){
                    if(data.succes)
                    {
                        $('.alert-success', form).removeClass('hidden');
                        window.location.href = window.location.href;
                    } else
                    {
                        $('.alert-danger', form).removeClass('hidden');
                    }
                }, 'json');
            }
        }
    );

});
/*
$(document).scroll(function() { });
*/
$( window ).resize(function() {
    //equalHeights('.banner','.banners-123');


});



function initCoverflow(elm) {

    coverflow = new FWDSimple3DCoverflow(
        {
            //required settings
            coverflowHolderDivId: elm,
            coverflowDataListDivId:"coverflowData",
            displayType:"responsive",
            autoScale:"yes",
            coverflowWidth:940,
            coverflowHeight:560,
            skinPath:"images/coverflow/skin_white",

            //main settings
            backgroundColor:"transparent",
            backgroundImagePath:"",
            backgroundRepeat:"repeat-x",
            showDisplay2DAlways:"no",
            coverflowStartPosition:$('#'+elm).data('cf-startslide'),
            coverflowTopology:$('#'+elm).data('cf-mode'),
            coverflowXRotation:0,
            coverflowYRotation:0,
            numberOfThumbnailsToDisplayLeftAndRight:"5",
            infiniteLoop:$('#'+elm).data('cf-loop'),
            rightClickContextMenu:"default",

            //thumbnail settings
            thumbnailWidth:300,
            thumbnailHeight:500,
            thumbnailXOffset3D:86,
            thumbnailXSpace3D:78,
            thumbnailZOffset3D:200,
            thumbnailZSpace3D:93,
            thumbnailYAngle3D:0,
            thumbnailXOffset2D:20,
            thumbnailXSpace2D:30,
            thumbnailHoverOffset:100,
            thumbnailBorderSize:0,
            thumbnailBackgroundColor:"#999999",
            thumbnailBorderColor1:"#FFFFFF",
            thumbnailBorderColor2:"#000000",
            transparentImages:"yes",
            thumbnailsAlignment:"center",
            maxNumberOfThumbnailsOnMobile:13,
            showThumbnailsGradient:"yes",
            thumbnailGradientColor1:"rgba(0, 0, 0, 0)",
            thumbnailGradientColor2:"rgba(0, 0, 0, 0)",
            showText:"yes",
            textOffset:10,
            showThumbnailBoxShadow:"yes",
            thumbnailBoxShadowCss:"0 3px 4px 0 rgba(0,0,0,0.5)",
            showTooltip:"no",
            dynamicTooltip:"yes",
            showReflection:"no",
            reflectionHeight:60,
            reflectionDistance:0,
            reflectionOpacity:.4,

            //controls settings
            slideshowDelay:$('#'+elm).data('cf-pause'),
            autoplay:$('#'+elm).data('cf-autoplay'),
            disableNextAndPrevButtonsOnMobile:"no",
            controlsMaxWidth:700,
            slideshowTimerColor:"#FFFFFF",
            controlsPosition:"bottom",
            controlsOffset:15,
            showPrevButton:$('#'+elm).data('cf-controls'),
            showNextButton:$('#'+elm).data('cf-controls'),
            showSlideshowButton:$('#'+elm).data('cf-controls'),
            showScrollbar:$('#'+elm).data('cf-pager'),
            disableScrollbarOnMobile:"yes",
            enableMouseWheelScroll:"yes",
            scrollbarHandlerWidth:200,
            scrollbarTextColorNormal:"#000000",
            scrollbarTextColorSelected:"#FFFFFF",
            addKeyboardSupport:"yes",

            //categories settings
            showCategoriesMenu:"no",
            startAtCategory:1,
            categoriesMenuMaxWidth:700,
            categoriesMenuOffset:25,
            categoryColorNormal:"#999999",
            categoryColorSelected:"#FFFFFF",

            //lightbox settings
            addLightBoxKeyboardSupport:"yes",
            showLightBoxNextAndPrevButtons:"yes",
            showLightBoxZoomButton:"yes",
            showLightBoxInfoButton:"yes",
            showLightBoxSlideShowButton:"yes",
            showLightBoxInfoWindowByDefault:"no",
            slideShowAutoPlay:"no",
            lightBoxVideoAutoPlay:"no",
            lightBoxVideoWidth:640,
            lightBoxVideoHeight:480,
            lightBoxIframeWidth:800,
            lightBoxIframeHeight:600,
            lightBoxBackgroundColor:"#000000",
            lightBoxInfoWindowBackgroundColor:"#FFFFFF",
            lightBoxItemBorderColor1:"#fcfdfd",
            lightBoxItemBorderColor2:"#e4FFe4",
            lightBoxItemBackgroundColor:"#333333",
            lightBoxMainBackgroundOpacity:.8,
            lightBoxInfoWindowBackgroundOpacity:.9,
            lightBoxBorderSize:0,
            lightBoxBorderRadius:20,
            lightBoxSlideShowDelay:4000

        });
}

function createBXslider(elm){
    $(elm).each(function(index) {
        c_id = $(this).attr('id');
        //console.log(c_id);
        var options = {};
        options.mode = $(this).data('bx-mode');
        options.auto = $(this).data('bx-auto');
        options.pause = $(this).data('bx-pause');
        options.loop = $(this).data('bx-loop');
        options.pager = $(this).data('bx-pager');
        options.controls = $(this).data('bx-controls');
        options.ticker = $(this).data('bx-ticker');
        options.speed = $(this).data('bx-speed');
        options.minSlides  = $(this).data('bx-minslides') ? $(this).data('bx-minslides') : 1;
        options.maxSlides  = $(this).data('bx-maxslides') ? $(this).data('bx-maxslides') : 1;
        options.slideWidth  = $(this).data('bx-slidewidth') ? $(this).data('bx-slidewidth') : 0;

        options.nextText = '';
        options.prevText = '';
        console.log(options);
        $(this).bxSlider(options);
    });
    return;

    car_id = $(elm).attr('id');
    var cms_options = 'options_'+car_id;
    this[cms_options].value++;
    //console.log(this[cms_options]);
    var options = {};

    // auto
    if ('auto' in this[cms_options]) {
        switch(this[cms_options].auto){
            case '1':
                options.auto = true;
                break;
            default:
                options.auto = false;
        }
    }else{
        options.auto = true;
    }

    // mode
    if ('mode' in this[cms_options]) {
        switch(this[cms_options].mode){
            case '0':
                options.mode = 'fade';
                break;
            case '2':
                options.mode = 'vertical';
                break;
            case '3':
                options.mode = 'horizontal';
                options.ticker = true;
                options.speed = 8000;
                break;
            default:
                options.mode = 'horizontal';
        }
    }else{
        options.mode = 'horizontal';
    }

    //console.log(options);

    $('#'+car_id).bxSlider(options);
}

// set <img src> as a css background-image
/*function imagesToBackground(){
    $('.image-on-bg').each(function() {
        var imageUrl = $(this).find('img').first().attr('src');
        imageUrl = encodeURI(imageUrl);
        $(this).find('img').first().remove();
        $(this).css('background-image', 'url(' + imageUrl + ')');
    });
}*/

function imagesToBackground(){
    $('.image-to-bg').each(function() {
        //var imageHeight = $(this).attr('height');
        var imageUrl = $(this).attr('src');
        imageUrl = encodeURI(imageUrl);
        var parentElement = $(this).closest('div');
        parentClass = '.'+$(this).closest('section').attr('class').split(' ').pop();
        $(this).remove();
        parentElement.addClass('image-block').css('background-image', 'url(' + imageUrl + ')');
        equalHeights('.block > div', parentClass);
    });
}

function equalHeights(element, container){
    if (typeof container === 'undefined') {
        c = $(element).parent();
    } else {
        c = $(container);
    }

    var highestBox = 0;
    c.find(element).each(function(){
        if($(this).height() > highestBox){
            highestBox = $(this).height();
        }
    })
    c.find(element).height(highestBox);

}

/*
function placeholderFallback() {
    if(!Modernizr.input.placeholder) {
        $("input[placeholder], textarea[placeholder]").each(function() {
            if($(this).val()==""){
                $(this).val($(this).attr("placeholder"));
                $(this).focus(function(){
                    if($(this).val()==$(this).attr("placeholder")) {
                        $(this).val("");
                        $(this).removeClass('placeholder');
                    }
                });
                $(this).blur(function(){
                    if($(this).val()==""){
                        $(this).val($(this).attr("placeholder"));
                        $(this).addClass('placeholder');
                    }
                });
            }
        });
        $('form').submit(function(){
            // first do all the checking for required element and form validation.
            // Only remove placeholders before final submission
            var placeheld = $(this).find('[placeholder]');
            for(var i=0; i<placeheld.length; i++){
                if($(placeheld[i]).val() == $(placeheld[i]).attr('placeholder')) {
                    // if not required, set value to empty before submitting
                    $(placeheld[i]).attr('value','');
                }
            }
        });
    }
}*/


// Delay function
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        } else {
            error.insertAfter(element);
        }
    }
});


function init_leden()
{
    build_map(markerslist);

    $.each(markerslist_fill, function(cell, value) {
		if (typeof value !== 'undefined')
		{
			if (value.lng == '')
			{
				markerslist.splice(cell,1);
				$("#gmap-leden").gmap3({
					getlatlng:{
						address: value.data.adres + ' ' + value.data.postcode + ' ' + value.data.plaats,
						callback: function(results){
							if ( !results ) return;
							latlng_db = new Array();
							latlng_db.push({name: 'id', value: value.data.id});
							latlng_db.push({name: 'lat', value: results[0].geometry.location.lat()});
							latlng_db.push({name: 'lng', value: results[0].geometry.location.lng()});
							$.post(window.base_url + 'engine/latlng.php', latlng_db, function(data) {
							});
						}
					}
				});
			}
		}
    });

	$('.lid').click(function(e) {
		$('.lid').removeClass('active');
		$(this).addClass('active');
		var map = $("#gmap-leden").gmap3("get");
		var marker = $("#gmap-leden").gmap3({
				get: {
					name:"marker",
					id: $(this).prop('id').replace('lid-', 'mapspot-'),
					all: true
				}
			});
		if ((typeof marker[0] !== 'undefined') && (typeof marker[0] !== 'null') && (marker[0] !== null) && (marker[0] !== false))
		{
			map.setCenter(marker[0].getPosition());
			map.setZoom(13);
			google.maps.event.trigger(marker[0], 'click');
		}
	});
	
	$('#filter-rechtsgebied').change(function(e) {
		var map = $("#gmap-leden").gmap3("get");
		var marker = [];
		var $this = $(this);
		var filter_val = $('#filter-rechtsgebied').val();
		var search_val = $('#search').val().toLowerCase();
		$.each(markerslist, function(cell, value) {
			if (search_val.length > 1)
			{
				if ( (value.tag.indexOf(filter_val * 1) > -1) && ( ( value.data.naam.toLowerCase().indexOf(search_val) > -1 ) || ( value.data.plaats.toLowerCase().indexOf(search_val) > -1 ) ) )
				{
					marker.push(value);
					$('#lid-' + value.data.id).show();
				} else {
					$('#lid-' + value.data.id).hide();	
				}
			} else
			{
				if (value.tag.indexOf(filter_val * 1) > -1)
				{
					marker.push(value);
					$('#lid-' + value.data.id).show();
				} else {
					$('#lid-' + value.data.id).hide();	
				}
			}

		});
		build_map( marker );
	});
	
	
	$('#search').keypress(function(e) {
		if (e.keyCode == 13) {
			e.preventDefault();
		}
	});
	$('#search').keyup(function(e) {
		var map = $("#gmap-leden").gmap3("get");
		var marker = [];
		var $this = $(this);
		var filter_val = $('#filter-rechtsgebied').val();
		$.each(markerslist, function(cell, value) {
			if (filter_val.length > 1)
			{
				if ( (value.tag.indexOf(filter_val * 1) > -1) && ( ( value.data.naam.toLowerCase().indexOf($this.val()) > -1 ) || ( value.data.plaats.toLowerCase().indexOf($this.val()) > -1 ) ) )
				{
					marker.push(value);
					$('#lid-' + value.data.id).show();
				} else {
					$('#lid-' + value.data.id).hide();	
				}
			} else
			{
				if ( ( value.data.naam.toLowerCase().indexOf($this.val().toLowerCase()) > -1 ) || ( value.data.plaats.toLowerCase().indexOf($this.val().toLowerCase()) > -1 ) )
				{
					marker.push(value);
					$('#lid-' + value.data.id).show();
				} else {
					$('#lid-' + value.data.id).hide();	
				}
			}

		});
		build_map( marker );
	});
	
}

function rebuild_map(markers) 
{
	$("#gmap-leden").gmap3({
		clear: {
			name: ['marker', 'infowindow']
		}
	});
}

function build_map(markers)
{
	$("#gmap-leden").gmap3({
		clear: {
			name: ['marker', 'infowindow']
		},
        map:{
            address: 'Paderborn',
            options:{
                zoom: 7,
                minZoom: 4,
                maxZoom: 20,
                panControl: false,
				mapTypeId: "DNRV"
            },
		},
		styledmaptype:{
      		id: "DNRV",
      		styles: [

					 {
					 "featureType": "administrative",
					 "elementType": "labels.text.fill",
					 "stylers": [
					 {
					 "color": "#444444"
					 }
					 ]
					 },
					 {
					 "featureType": "landscape",
					 "elementType": "all",
					 "stylers": [
					 {
					 "color": "#f2f2f2"
					 }
					 ]
					 },
					 {
					 "featureType": "poi",
					 "elementType": "all",
					 "stylers": [
					 {
					 "visibility": "off"
					 }
					 ]
					 },
					 {
					 "featureType": "road",
					 "elementType": "all",
					 "stylers": [
					 {
					 "saturation": -100
					 },
					 {
					 "lightness": 45
					 }
					 ]
					 },
					 {
					 "featureType": "road.highway",
					 "elementType": "all",
					 "stylers": [
					 {
					 "visibility": "simplified"
					 }
					 ]
					 },
					 {
					 "featureType": "road.arterial",
					 "elementType": "labels.icon",
					 "stylers": [
					 {
					 "visibility": "off"
					 }
					 ]
					 },
					 {
					 "featureType": "transit",
					 "elementType": "all",
					 "stylers": [
					 {
					 "visibility": "off"
					 }
					 ]
					 },
					 {
					 "featureType": "water",
					 "elementType": "all",
					 "stylers": [
					 {
					 "color": "#becee3"
					 },
					 {
					 "visibility": "on"
					 }
					 ]
					 }
			
			]
		},
         marker:{
            options:{title:"Bekijk adresgegevens"},
            events:{
                click:function(marker, event, context){
					$('#leden-lijst').scrollTop($('#leden-lijst').scrollTop() + $('#lid-'+ context.data.id).offset().top - $('#leden-lijst').offset().top);
					$('.lid').removeClass('active');
					$('#lid-'+ context.data.id).addClass('active');
                    $(this).gmap3({
                        clear:"infowindow",
                        infowindow: {
                            latLng: marker.getPosition(),
                            options:{
                                content: "<div class='map-overlay'>" +
                                            "<p><b>" + context.data.naam + "</b><br>" +
                                            context.data.adres + "<br>" +
                                            context.data.postcode + " " + context.data.plaats + "</p>" +
                                            "<p>" +
                                            "<a href='" + context.data.website + "' target='_blank'>" + context.data.website + "</a></p>" +
                                         "</div>",
                                pixelOffset: new google.maps.Size(0, -30)
                                }
                            },

                        });
                        $(this).gmap3('get').panTo(marker.getPosition());
                    }
                },

            values: markers,
			animation: google.maps.Animation.DROP, 
            cluster:{
                radius: 100,
                20: {content: '<div class="cluster cluster-1">CLUSTER_COUNT</div>', width:70, height:70},
                50: {content: '<div class="cluster cluster-2">CLUSTER_COUNT</div>', width:70, height:70},
                100: {content: '<div class="cluster cluster-3">CLUSTER_COUNT</div>', width:70, height:70},
                events:{
                click: function(cluster){
                    var map = $(this).gmap3("get");
                    map.setCenter(cluster.main.getPosition());
                    map.setZoom(map.getZoom()+2);
                    }
                  }
                },
			 	callback: function (cl) {
                	clusterer = cl;
                }
				
            }
        }
    );
	
	/* .styledmaptype("DNRV", 
	[
		 {
		 "featureType": "administrative",
		 "elementType": "labels.text.fill",
		 "stylers": [
		 {
		 "color": "#444444"
		 }
		 ]
		 },
		 {
		 "featureType": "landscape",
		 "elementType": "all",
		 "stylers": [
		 {
		 "color": "#f2f2f2"
		 }
		 ]
		 },
		 {
		 "featureType": "poi",
		 "elementType": "all",
		 "stylers": [
		 {
		 "visibility": "off"
		 }
		 ]
		 },
		 {
		 "featureType": "road",
		 "elementType": "all",
		 "stylers": [
		 {
		 "saturation": -100
		 },
		 {
		 "lightness": 45
		 }
		 ]
		 },
		 {
		 "featureType": "road.highway",
		 "elementType": "all",
		 "stylers": [
		 {
		 "visibility": "simplified"
		 }
		 ]
		 },
		 {
		 "featureType": "road.arterial",
		 "elementType": "labels.icon",
		 "stylers": [
		 {
		 "visibility": "off"
		 }
		 ]
		 },
		 {
		 "featureType": "transit",
		 "elementType": "all",
		 "stylers": [
		 {
		 "visibility": "off"
		 }
		 ]
		 },
		 {
		 "featureType": "water",
		 "elementType": "all",
		 "stylers": [
		 {
		 "color": "#becee3"
		 },
		 {
		 "visibility": "on"
		 }
		 ]
		 }
		], {name: 'DRNV kaart'}
	);
	*/
}


